import React from "react";

// MATERIAL UI
import { styled } from "@mui/system";
import { Typography, Box, Button } from "@mui/material";

const ThemeCard = ({ image, title }) => {
  return (
    <Container className="theme-card">
      <Typography sx={{ mb: 1 }}>
        ID: {title}{" "}
        <Button
          className="data-theme-id"
          data-theme-id={title}
          color="secondary"
        >
          use this theme
        </Button>
      </Typography>
      <img src={image} alt="bookamrk manager theme" loading="lazy" />
    </Container>
  );
};

const Container = styled("div")({
  img: {
    borderRadius: 4,
    maxWidth: "100%",
    padding: 0,
    margin: "0 0 1.45rem",
  },

  button: {
    display: "none",
  },
});

const ThemeSection = styled(Box)(({ hideThird }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  gridGap: 32,
  marginTop: 24,

  "@media (max-width: 576px)": {
    gridTemplateColumns: "1fr",

    "& > :nth-of-type(3)": {
      display: hideThird ? "none" : "initial",
    },
  },
}));

export { ThemeSection };

export default ThemeCard;
