import React, { useEffect, useRef } from "react";

// ASSETS
import Ufo from "assets/ufo.svg";
import Satelite from "assets/satelit.svg";
import Planet1 from "assets/planet1.svg";
import Asteroid from "assets/asteroid.svg";
import Asteroid2 from "assets/asteroid2.svg";
import Galaxy from "assets/galaxy.svg";
import BlackHole from "assets/blackhole.svg";
import Deadthstar from "assets/deadthstar.svg";
import FalconMillenium from "assets/falcon-millenium.svg";

const getRandomLeft = () => {
  return `${Math.floor(Math.random() * 90) + 2}vw`;
};

const getRandomTop = (bodyHeight) => {
  return `${Math.floor(Math.random() * bodyHeight)}px`;
};

const getRandomChilds = (array) => {
  let index1 = Math.floor(Math.random() * array.length);
  let index2 = Math.floor(Math.random() * array.length);
  return [index1, index2];
};

const getRandomRotate = () => {
  const rotate = Math.floor(Math.random() * 379);
  return `rotate(${rotate}deg)`;
};

const generateStyle = () => {
  const top = Math.floor(Math.random() * 350) + 2;
  const left = Math.floor(Math.random() * 90) + 2;
  const rotate = Math.floor(Math.random() * 379);
  const style = {
    top: `${top}vh`,
    left: `${left}vw`,
    transition: "opacity .35",
    transform: `rotate(${rotate}deg)`,
  };

  return style;
};

const SpaceAssetManager = () => {
  const ref = useRef(null);

  useEffect(() => {
    let interval = setInterval(() => {
      let { children } = ref.current;
      const [index1, index2] = getRandomChilds(children);
      let asset1 = children[index1];
      let asset2 = children[index2];

      asset1.style.opacity = 0;
      asset2.style.opacity = 0;

      setTimeout(() => {
        asset1.style.left = getRandomLeft();
        asset1.style.top = getRandomTop(document.body.offsetHeight);
        asset2.style.left = getRandomLeft();
        asset2.style.top = getRandomTop(document.body.offsetHeight);
        asset1.style.rotate = getRandomRotate();
        asset2.style.rotate = getRandomRotate();

        setTimeout(() => {
          asset1.style.opacity = 0.5;
          asset2.style.opacity = 0.5;
        }, 600);
      }, 600);
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div ref={ref} className="space-asset-manager">
      <img
        className="asset"
        style={generateStyle()}
        src={Ufo}
        alt="asset-img"
      />
      <img
        className="asset"
        style={generateStyle()}
        src={Satelite}
        alt="asset-img"
      />
      <img
        className="asset"
        style={generateStyle()}
        src={Planet1}
        alt="asset-img"
      />
      <img
        className="asset"
        style={generateStyle()}
        src={Asteroid}
        alt="asset-img"
      />
      <img
        className="asset"
        style={generateStyle()}
        src={Galaxy}
        alt="asset-img"
      />
      <img
        className="asset"
        style={generateStyle()}
        src={BlackHole}
        alt="asset-img"
      />
      <img
        className="asset"
        style={generateStyle()}
        src={Asteroid2}
        alt="asset-img"
      />
      <img
        className="asset"
        style={generateStyle()}
        src={Deadthstar}
        alt="asset-img"
      />
      <img
        className="asset"
        style={generateStyle()}
        src={FalconMillenium}
        alt="asset-img"
      />

      <img
        className="asset"
        style={generateStyle()}
        src={Ufo}
        alt="asset-img"
      />
      <img
        className="asset"
        style={generateStyle()}
        src={Asteroid}
        alt="asset-img"
      />
      <img
        className="asset"
        style={generateStyle()}
        src={BlackHole}
        alt="asset-img"
      />
      <img
        className="asset"
        style={generateStyle()}
        src={Deadthstar}
        alt="asset-img"
      />
    </div>
  );
};
export default SpaceAssetManager;
