import React, { useEffect, useState } from "react";

// MATERIAL UI
import { Typography, Button, Link } from "@mui/material";
import { styled } from "@mui/system";

// COMPONENTS
import Layout from "components/Layout";
import FeatureSection from "components/FeatureSection";
import ThemeCard, { ThemeSection } from "components/ThemeCard";
import SpaceAssetManager from "components/SpaceAssetsManager";
import CallToAction from "components/CallToAction";

// Videos
import Video1 from "assets/videos/lksdr.mp4";
import Video2 from "assets/videos/jfyne.mp4";
import Video3 from "assets/videos/iydme.mp4";
import Video4 from "assets/videos/ynsxo.mp4";

// IMAGES
import Rocket from "assets/Rocket.svg";
import ThemeBento from "assets/themes/Bento.png";
import ThemePratama from "assets/themes/Pratama.png";
import ThemeLawson from "assets/themes/Lawson.png";
import HeroImage from "assets/hero-image.svg";

import { getCompanies } from "../utils/utils";

const IndexPage = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => setIsMounted(true), 100);
  }, []);

  return (
    <Layout>
      {/* ASSETS */}
      <RocketAsset
        className={`${isMounted && "mounted"}`}
        src={Rocket}
        alt="landing-asset"
      />
      <SpaceAssetManager />

      {/* HERO */}
      <Wrapper>
        <Typography
          data-sb-id="hero--title"
          className="hero--title"
          variant="h1"
          sx={{ textAlign: "center" }}
        >
          Bookmarking made simple
        </Typography>
        <Typography
          data-sb-id="hero--subtitle"
          className="hero--subtitle"
          variant="h4"
          component="h2"
          sx={{ textAlign: "center" }}
        >
          Keep your bookmarks organized with ease. The minimalistic, yet
          powerful bookmarking tool for productivity people.
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={{ margin: "auto" }}
          href="https://chrome.google.com/webstore/detail/pau/oiblkeiiodficdoblmjihonngeejcmbo"
          target="_blank"
          rel="noreferrer"
          data-splitbee-event="Redirect: Chrome Store"
        >
          Add Pau To My Browser 🚀
        </Button>
        <img className="hero--image" src={HeroImage} />
      </Wrapper>

      {/* SOCIAL PROOF */}
      <Typography variant="h4" sx={{ mt: 4, mb: 4, textAlign: "center" }}>
        Empowering people at:
      </Typography>
      <CompaniesContainer>
        {getCompanies(["The Communion", "Enso", "Kueski", "Hudu"]).map((c) => {
          return (
            <Link key={c.name} href={c.url} target="_blank">
              <CompanyImage src={c.image} alt={c.name} />
            </Link>
          );
        })}
      </CompaniesContainer>

      {/* FEATURES */}
      <FeatureSection
        title={features.collections.title}
        description={features.collections.description}
        video={Video1}
      />
      <FeatureSection
        title={features.layouts.title}
        description={features.layouts.description}
        video={Video2}
      />
      <FeatureSection
        title={features.share.title}
        description={features.share.description}
        video={Video3}
      />
      <FeatureSection
        title={features.search.title}
        description={features.search.description}
        video={Video4}
      />

      <Typography variant="h3">Personalize your dashboard theme</Typography>
      <Typography sx={{ maxWidth: 900 }}>
        Choose from one of our trendy themes and customize your bookmark manager
        extension’s dashboard to fit your personality and style.
      </Typography>
      <ThemeSection hideThird>
        <ThemeCard title="Bento" image={ThemeBento} />
        <ThemeCard title="Patrama" image={ThemePratama} />
        <ThemeCard title="Lawson" image={ThemeLawson} />
      </ThemeSection>
      <Button
        variant="contained"
        sx={{ margin: "auto", display: "table" }}
        href="/themes"
      >
        See more themes!
      </Button>

      <Typography
        sx={{
          lineHeight: 1.2,
          fontStyle: "italic",
          maxWidth: 800,
          textAlign: "center",
          margin: "auto",
          mt: 20,
          mb: 20,
        }}
      >
        Save time and <b>focus on what matters</b> by using Pau{" "}
        <b>Bookmark Manager</b> to save and <b>organize your links</b>. Our tab
        manager extension helps you increase your productivity by eliminating
        the time you spend thinking of where to save your favorite stories,
        articles, videos, or anything else you find online. With Pau Bookmark
        Manager, you can save and{" "}
        <b>retrieve your bookmarks with just one click</b>.
      </Typography>

      <CallToAction
        title="Never get lost between lots of links again!"
        description="Ready to save links faster, stay organized and focus more on what matters? Add Pau to your chrome extension now to increase your productivity."
        cta={
          <Button
            variant="contained"
            href="https://chrome.google.com/webstore/detail/pau/oiblkeiiodficdoblmjihonngeejcmbo"
            target="_blank"
            rel="noreferrer"
            data-splitbee-event="Redirect: Chrome Store"
          >
            Add Pau To My Browser 🚀
          </Button>
        }
      />
    </Layout>
  );
};

const Wrapper = styled("div")(({ theme }) => ({
  position: "relative",
  textAlign: "center",
  marginBottom: 60,

  "& .hero--title": {
    marginTop: 120,
    fontSize: 80,
  },
  "& .hero--subtitle": {
    margin: "auto",
    marginTop: 60,
    marginBottom: 40,
    maxWidth: 800,
  },
  "& .hero--image": {
    width: "100%",
    marginTop: 100,
  },

  [theme.breakpoints.down("md")]: {
    "& .hero--title": {
      fontSize: 50,
    },
  },
}));

const CompaniesContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",

  "& img": {
    marginBottom: 16,
  },
});

const RocketAsset = styled("img")({
  position: "absolute",
  zIndex: "-3",
  top: "100vh",
  right: "10vw",
  width: "95vw",
  opacity: "0",
  transform: "translate(-300px, 300px)",
  transition: "2s",

  "@media (max-width: 769px)": {
    opacity: "0.4 !important",
  },

  "&.mounted": {
    transform: "translate(0, 0)",
    opacity: 0.9,
  },
});

const CompanyImage = styled("img")({
  height: 40,
  marginLeft: 12,
  marginRight: 12,
});

const features = {
  collections: {
    title: "Saving & organizing bookmarks have never been easier",
    description:
      "Save bookmarks and organize them neatly in folders or subfolders on the go with a single click. Our Bookmark manager makes it easier to keep track of the links, stories, and articles you like to revisit.",
  },
  layouts: {
    title: "Customize the layouts to fit your needs",
    description:
      "Choose between the multiple layouts available on our tab manager extension and customize it to work the exact way you want. Pau features simple and modern layouts that give you all relevant information about your bookmarks at a glance.",
  },
  share: {
    title: "Share your bookamrks to your teammates",
    description:
      "Share the important links and resources to your teammates easily. Then they can import the bookmarks collection with just one click.",
  },
  access: {
    title: "Access your content from anywhere",
    description:
      "Pau uses the chrome bookmark api to automatically sync your content between all your devices. This means you will be able to access your bookmarks on your smartphones, tablets, and personal computers.",
  },
  search: {
    title: "Retrieve bookmarks faster with Pau search function",
    description:
      "Use the custom search feature on Pau to search your library of content by keywords, title or urls. Say goodbye to spending your valuable time searching through your bookmarks.",
  },
};

export default IndexPage;
