import React from "react";

// MATERIAL UI
import { styled } from "@mui/system";
import { Typography } from "@mui/material";

const FeatureSection = ({ title, description, video, cta }) => {
  return (
    <Container>
      <LeftSide>
        <Typography variant="h3" sx={{ mt: 0 }}>
          {title}
        </Typography>
        <Typography sx={{ lineHeight: 1.2, mb: 0.5 }}>{description}</Typography>
        {cta && cta}
      </LeftSide>
      <RightSide>
        <Video video={video} />
      </RightSide>
    </Container>
  );
};

const Video = ({ video }) => (
  <video autoPlay muted loop playsInline>
    <source src={video} type="video/mp4" />
  </video>
);

const Container = styled("div")({
  position: "relative",
  zIndex: 1,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "120px 0",

  "@media (max-width: 1005px)": {
    flexDirection: "column",
  },
});

const LeftSide = styled("div")({
  width: "38%",
  paddingRight: 60,

  "@media (max-width: 1005px)": {
    width: "100%",
    paddingRight: 0,
    marginBottom: 16,
  },
});

const RightSide = styled("div")({
  width: "62%",

  "@media (max-width: 1005px)": {
    width: "auto",
  },

  video: {
    width: "100%",
    borderRadius: 8,
    boxShadow:
      "0 1px 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.12),0 4px 4px rgba(0, 0, 0, 0.16), 0 8px 8px rgba(0, 0, 0, 0.2)",
  },
});

export default FeatureSection;
