import CommunionLogo from "../assets/images/companies/TheCommunionLogo.svg";
import KueskiLogo from "../assets/images/companies/KueskiLogo.png";
import EnsoLogo from "../assets/images/companies/EnsoLogo.svg";
import GenericLogoCompany from "../assets/images/companies/GenericLogoCompany.svg";
import HuduLogoCompany from "../assets/images/companies/HuduLogoCompany.svg";

const companies = [
  {
    name: "The Communion",
    image: CommunionLogo,
    url: "https://communionstd.com/",
    description:
      "ANIMATOR AND MOTION DESIGNER, Explainer videos | Intros | Gifs | Mobile Animations | Illustration",
  },
  {
    name: "Kueski",
    image: KueskiLogo,
    url: "https://www.kueskipay.com/",
  },
  {
    name: "Enso",
    image: EnsoLogo,
    url: "https://www.joinenso.com/",
  },
  {
    name: "Company Name",
    image: GenericLogoCompany,
    url: "https://companyUrl.dev/"
  },
  {
    name: "Hudu",
    image: HuduLogoCompany,
    url: "https://www.hudu.com/"
  }
];

export const getCompanies = (input) => {
  return companies.filter((c) => input.includes(c.name));
};
